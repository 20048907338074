<template>
  <div>
    <h4 class="text-muted mt-2 px-3">
      {{ heading }}
    </h4>

    <ul class="list-unstyled">
      <li
        v-for="league in leagues"
        :key="league.id"
        class="dropdown-item"
      >
        <nuxt-link
          class="text-black text-decoration-none"
          :to="localePath({ name: 'league-slug', params: { slug: league.slug } })"
        >
          {{ league.competition_data.name }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TopNavLeagues',

  props: {
    heading: {
      required: true,
      type: String,
    },
    leagues: {
      required: true,
      type: Array,
    },
  },
});
</script>
