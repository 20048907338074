<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-black">
    <div class="container-fluid">
      <a
        class="navbar-brand d-flex align-items-center"
        href="/"
      >
        <icon-logo
          class="me-2"
          :height="32"
          :width="32"
        />

        Fútbol - hoy en vivo 🇲🇽
      </a>

      <button
        aria-controls="navbarNav"
        :aria-expanded="show"
        aria-label="Toggle navigation"
        class="navbar-toggler"
        :class="{ collapsed: !show }"
        data-bs-toggle="collapse"
        type="button"
        @click="toggle"
      >
        <span class="navbar-toggler-icon"/>
      </button>

      <div
        id="navbarNav"
        v-click-outside="closeLeagues"
        class="navbar-collapse"
        :class="{ collapse, collapsing, show }"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <nuxt-link
              aria-current="page"
              class="nav-link"
              to="/"
            >
              {{ $t('navbar.top.home') }}
            </nuxt-link>
          </li>

          <li class="nav-item dropdown">
            <a
              :aria-expanded="leaguesShow"
              class="nav-link dropdown-toggle"
              :class="{ show: leaguesShow }"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              @click.prevent.stop="toggleLeagueShow"
            >
              {{ $t('navbar.top.leagues') }}
            </a>

            <div
              class="dropdown-menu border-gray-500"
              :class="{ show: leaguesShow }"
            >
              <top-nav-leagues
                class="mb-2"
                :heading="$t('navbar.top.top_leagues')"
                :leagues="nav.top_leagues"
              />

              <top-nav-leagues
                :heading="$t('navbar.top.popular_leagues')"
                :leagues="nav.popular_leagues"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import IconLogo from '~/components/icons/IconLogo.vue';
import { CACHE_KEY_TOP_NAV, TIME_HOUR } from '~/config/cache';
import TopNavLeagues from '~/components/general/TopNavLeagues.vue';

export default {
  name: 'TopNav',

  components: {
    TopNavLeagues,
    IconLogo,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  async setup() {
    const { $cacheFetchDomain } = useNuxtApp();
    const timezone = useTimezone().value;
    const params = {
      tz: timezone,
      geo_alpha2: 'MX',
    };
    const { data: nav, error } = await useAsyncData('top-nav', () => $cacheFetchDomain({
      url: '/navbar',
      key: parseCacheKey(CACHE_KEY_TOP_NAV, params),
      params,
      maxAge: TIME_HOUR,
    }));

    if (error.value) {
      throw error.value;
    }

    return {
      nav: nav.value.data,
    };
  },

  data: () => ({
    show: false,
    collapsing: false,
    leaguesShow: false,

    timeout: null,
  }),

  computed: {
    collapse() {
      if (this.collapsing) {
        return false;
      }

      return true;
    },
  },

  watch: {
    $route() {
      this.closeNav();
      this.closeLeagues();
    },
  },

  methods: {
    toggle() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.collapsing = true;

      this.timeout = setTimeout(() => {
        this.collapsing = false;
        this.show = !this.show;
      }, 400);
    },

    toggleLeagueShow() {
      this.leaguesShow = !this.leaguesShow;
    },

    closeNav() {
      this.show = false;
    },

    closeLeagues() {
      this.leaguesShow = false;
    },
  },
};
</script>
