export const TIME_HALF_MINUTE = 30;
export const TIME_MINUTE = 60;
export const TIME_HOUR = TIME_MINUTE * 60;
export const TIME_DAY = TIME_HOUR * 24;
export const TIME_WEEK = TIME_DAY * 7;
export const TIME_HALF_HOUR = TIME_MINUTE * 30;

export const HEADER_CACHE_KEY = 'X-CACHE-KEY';
export const HEADER_CACHE_MAX_AGE = 'X-CACHE-MAX-AGE';
export const HEADER_CACHE_SKIP = 'X-CACHE-SKIP';
export const HEADER_CACHE_IGNORE_GEO = 'X-CACHE-IGNORE-GEO';
export const HEADER_CACHE_IGNORE_LOCALE = 'X-CACHE-IGNORE-LOCALE';

export const CACHE_KEY_GEO = 'geo-{ip}';
export const CACHE_KEY_TOP_NAV = 'top-nav';
export const CACHE_KEY_SPORT_EVENTS = 'sport-events-{from}-{to}-{limit}-{offset}-{tz}-{statuses}';

export const CACHE_KEY_MATCH_PAGE_NEWS_POSTS = 'match-page-news-posts-{slug}';
export const CACHE_KEY_MATCH_PAGE_NEWS_POSTS_DETAILS = 'match-page-news-posts-details-{id}';
export const CACHE_KEY_MATCH_PAGE_NEWS_POSTS_H2H = 'match-page-news-posts-h2h-{id}';
export const CACHE_KEY_MATCH_PAGE_NEWS_POSTS_TEAM_FORM = 'match-page-news-posts-h2h-{id}-{teamId}';
export const CACHE_KEY_CONTENT = 'content-{page}-{webpage}';

export const CACHE_KEY_LEAGUE_PAGE = 'league-page-{slug}';
export const CACHE_KEY_LEAGUE_PAGE_MATCHES = 'league-page-matches-{competition_id}-{from}-{to}-{limit}-{statuses}-{tz}';

export const CACHE_KEY_IMAGE_MATCH = 'image-match-{slug}';
export const CACHE_KEY_IMAGE_DAY = 'image-day-{date}';
