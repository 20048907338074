import { default as indexAILLjHwrsdMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20241030095545234/pages/about/index.vue?macro=true";
import { default as indexnrCXcGFLfLMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20241030095545234/pages/contact/index.vue?macro=true";
import { default as indexTdJJLut52AMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20241030095545234/pages/index.vue?macro=true";
import { default as _91slug_93tgFzf9wS2OMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20241030095545234/pages/league/[slug].vue?macro=true";
import { default as _91slug_93ccMFikOKRvMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20241030095545234/pages/match/[slug].vue?macro=true";
import { default as indexVKcG03gTXxMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20241030095545234/pages/privacy-policy/index.vue?macro=true";
export default [
  {
    name: "about___es-mx",
    path: "/sobre-nosotros",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20241030095545234/pages/about/index.vue")
  },
  {
    name: "contact___es-mx",
    path: "/contacto",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20241030095545234/pages/contact/index.vue")
  },
  {
    name: "index___es-mx",
    path: "/",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20241030095545234/pages/index.vue")
  },
  {
    name: "league-slug___es-mx",
    path: "/liga/:slug()",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20241030095545234/pages/league/[slug].vue")
  },
  {
    name: "match-slug___es-mx",
    path: "/noticias/:slug()",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20241030095545234/pages/match/[slug].vue")
  },
  {
    name: "privacy-policy___es-mx",
    path: "/politica-de-privacidad",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20241030095545234/pages/privacy-policy/index.vue")
  }
]